import Footer from "../../components/footer";
import Header from "../../components/header";
import ImageSlider from "../../components/imageSlider";
import './curso.css';
import FotoThay from '../../assets/fotoThay.webp';
import logo from '../../assets/logo.webp'
import cardAvancado from '../../assets/cards/cardAvancado.webp';
import cardIniciante from '../../assets/cards/cardIniciante.webp';
import cardEspecializacao from '../../assets/cards/cardEspecializacao.webp';
import cardNoivas from '../../assets/cards/cardNoivas.webp';
import cardDupla from '../../assets/cards/cardDupla.webp';
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Curso(){

    

    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div >
            <Header/>
            <div className="cursoArea">
                <div className="logoArea">
                    <div className="logoThayCursos">
                        <img src={logo}/>
                    </div>
                    <div className="infoThay">
                        <div className="textArea">
                            
                            <h1>Curso Vip</h1>
                            <p>Olá, eu sou Thaysa, mas pode me chamar de Thay.</p>
                            <p>Sou especialista em penteados para noivas e atendimentos sociais.</p>
                            <p>Nossos cursos de penteadista são projetados para te ajudar a dominar a arte do penteado do através das mais recentes técnicas, 
                                tendências e ferramentas do comércio, dando-lhe as habilidades e a confiança necessárias para criar looks deslumbrantes para 
                                mulheres de todos os estilos e personalidades. Seja você um penteadista experiente procurando expandir seu repertório ou um 
                                iniciante procurando entrar no mundo do penteado de noiva, nossos cursos são adaptados para atender às suas necessidades e objetivos exclusivos. 
                                Oferecemos uma variedade de cursos, do iniciante ao avançado, e nossa programação flexível permite que você aprenda no seu próprio ritmo e no seu 
                                próprio tempo. Junte-se a nós em nossa missão de tornar os penteados inesquecíveis nas vidas das clientes.
                            </p>  
                        </div>
                    </div>
                </div>
                <div className="imgAreas">
                    <img src={FotoThay}/>
                </div>
               
            </div>
            <div className="cursoCards">

                <div className="mod">
                    <div className="cardCurso">
                        <div className="iniciante">
                            <img src={cardIniciante}/>
                        </div>
                    </div>
                    <div className="linkCurso"><Link to="/iniciante">Curso Iniciante</Link></div>
                </div>
                <div className="mod">
                    <div className="cardCurso">
                        <div className="especializacao">
                            <img src={cardEspecializacao}/>                       
                        </div>
                    </div>
                    <div className="linkCurso"><Link to="/especializacao">Curso Especialização</Link></div>
                </div>
                <div className="mod">
                    <div className="cardCurso">
            
                        <div className="avancado">
                            <img src={cardAvancado}/>
                            
                        </div>
                    </div>
                    <div className="linkCurso"><Link to="/avancado">Curso Avançado</Link></div>
                </div>
                <div className="mod">
                    <div className="cardCurso">
                        <div className="noivas">
                            <img src={cardNoivas}/>
                            
                        </div>
                    </div>
                    <div className="linkCurso"><Link to="/cursonoivas">Especialização Noivas</Link></div>
                </div>
                <div className="mod">
                    <div className="cardCurso">
                                        
                        <div className="dupla">
                            <img src={cardDupla}/>                            
                        </div>
                    </div>    
                    <div className="linkCurso"><Link to="/dupla">Curso em Dupla</Link></div>        
                </div>
                                
            </div>
            <Footer/>
        </div>
    )
}