import { useEffect, useState } from "react";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import './home.css';
import ImageSlider from "../../components/imageSlider";
import { motion } from "framer-motion";
import Typical from 'react-typical';
import Footer from "../../components/footer";
import ImageSliderCards from "../../components/imageSliderCards";


export default function Home(){
    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])


    return(
        <div>
            <Header />
                <section className="sections">
                    <section className="bloco" id="infoCards">
                        <div className="info-text">
                            <h1>Bem vindo(a), Aqui você vai encontrar todas as informações sobre {' '}
                                <Typical
                                className="typical"
                                loop={Infinity}
                                wrapper="b"
                                steps={[
                                    'Noivas', 3000,
                                    'Cursos', 3000,
                                    'mim', 3000,
                                    'produções', 3000
                                ]}/>
                            </h1>
                            <p>
                                Bem-vindo ao nosso site, onde celebramos a arte de pentear e a 
                                alegria de fazer noivas e mulheres se sentirem lindas em seu grande 
                                dia ou evento. Sou uma penteadista apaixonada por criar penteados resistentes, 
                                leves e personalizados, que refletem seu estilo pessoal e realçam sua beleza natural.
                            </p>
                        </div>
                    </section>
                    <section className="bloco" id="textInfo">
                        <div className="img">
                            <div className="imgArea">
                                <ImageSlider />
                            </div>
                        </div>
                    </section>
                </section>
                <section className="cardArea">
                    
                    <h1>Um pouco sobre o que falam sobre mim</h1>
                    <div className="cards">
                        <ImageSliderCards />
                    </div>
                </section>      
            <Footer /> 
        </div>
         
         
    )
}
/*


            */