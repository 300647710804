
import feedback1 from '../../assets/feedbacks/feedback1.webp';
import feedback2 from '../../assets/feedbacks/feedback2.webp';
import feedback3 from '../../assets/feedbacks/feedback3.webp';
import feedback4 from '../../assets/feedbacks/feedback4.webp';
import feedback5 from '../../assets/feedbacks/feedback5.webp';
import feedback6 from '../../assets/feedbacks/feedback6.webp';
import feedback7 from '../../assets/feedbacks/feedback7.webp';


export const imageDataFeedbacks = [
    {
        image:feedback1
    },
    {
        image:feedback2
    },
    {
        image:feedback3
    },
    {
        image:feedback4
    },
    {
        image:feedback5
    },
    {
        image:feedback6
    },
    {
        image:feedback7
    },
]