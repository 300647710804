
import producao1 from '../../assets/producoes/producao1.webp';
import producao2 from '../../assets/producoes/producao2.webp';
import producao3 from '../../assets/producoes/producao3.webp';
import producao4 from '../../assets/producoes/producao4.webp';
import producao5 from '../../assets/producoes/producao5.webp';
import producao6 from '../../assets/producoes/producao6.webp';
import producao7 from '../../assets/producoes/producao7.webp';


export const imageDataProd = [
    {
        image: producao1
    },
    {
        image: producao2
    },
    {
        image: producao3
    },
    {
        image: producao4
    },
    {
        image: producao5
    },
    {
        image: producao6
    },
    {
        image: producao7
    },
    
]