
import noiva1 from '../assets/images/noiva1.webp';
import noiva11 from '../assets/images/noiva1-1.webp';
import noiva111 from '../assets/images/noiva1-1-1.webp';
import noiva2 from '../assets/images/noiva2.webp';
import noiva22 from '../assets/images/noiva2-2.webp';
import noiva4 from '../assets/images/noiva4.webp';
import noiva5 from '../assets/images/noiva5.webp';
import noiva55 from '../assets/images/noiva5-5.webp';
import noiva7 from '../assets/images/noiva7.webp';
import noiva8 from '../assets/images/noiva8.webp';



export const imageData = [
    {
        image: noiva1
    },
    {
        image: noiva11
    },
    {
        image: noiva111
    },
    {
        image: noiva2
    },
    {
        image: noiva22
    },
    {
        image: noiva4
    },
    {
        image: noiva5
    },
    {
        image: noiva55
    },
    {
        image: noiva7
    },
    {
        image: noiva8
    },
]